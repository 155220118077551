<template>
  <div class="container oci-check-in-out-body">
    <WasteNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Confirm job details</b>
        </b-row>
        <b-row>      
          <b-col cols="4">
            Job:
          </b-col>
          <b-col>
          {{currentBooking.job}}
          </b-col>
        </b-row>       
        <b-row>
          <b-col cols="4">
            Type:
          </b-col>
          <b-col>
            <div v-if="currentBooking.type == 'Waste'" style="display: flex; align-items: center;">
              <feather-icon 
                id="type" 
                icon="TrashIcon" 
                size="14" 
                style="stroke-width: 3;"
              />
              &nbsp;{{currentBooking.type}}
            </div>
            <div v-if="currentBooking.type == 'Delivery / Collection'" style="display: flex; align-items: center;">
              <feather-icon 
                id="type" 
                icon="TruckIcon" 
                size="14" 
                style="stroke-width: 3;"
              />
              &nbsp;{{currentBooking.type}}
            </div>                        
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Client:
          </b-col>
          <b-col>
          {{currentBooking.client_name}}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Address:
          </b-col>
          <b-col>
          {{currentBooking.site_main_address}}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Waste:
          </b-col>
          <b-col>
            {{WasteDescriptions}}              
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Weight:
          </b-col>
          <b-col>       
          {{CalculateEstimatedWeight}}Kg
          </b-col>
        </b-row>  
        <b-row class="pb-1">
          <b-col class="col-12 driver-upload-button">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
              :disabled="isInputDisabled"
            >
              Confirm and complete
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 
        <b-row class="pb-1">
          <b-col class="col-12 driver-back-button">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
        </b-row>                       
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton, BImg, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import WasteNumberView from '@/views/WasteNumberView.vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import { generalMethods } from '@/components/General-Functions'

export default {
  components: {
    WasteNumberView,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
    BImg,
  },
  computed: {
    ...mapGetters({ bookingDockets: 'app/getBookingDocketDetails' }),
    ...mapGetters({ wasteMaterials: 'app/getWasteMaterials' }),
    ...mapGetters({ isLoadingBooking: 'app/getIsLoadingBooking' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ bookingID: 'app/getDriverBookingID' }),
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    },
    dynamicClass() {
      console.log('dynamicClass', this.currentBooking.status)
      if (this.currentBooking.status == 'to_do') {
        return 'oci-offered-salutation';
      } else if (this.currentBooking.status == 'started'){
        return 'oci-accepted-salutation';
      } else if (this.currentBooking.status == 'completed'){
        return 'oci-completed-salutation';      
      } else if (this.currentBooking.status == 'rejected'){
        return 'oci-noshow-salutation';
      } else {
        return 'oci-completed-salutation';            
      }   
    },
    WasteDescriptions() {
      let waste_description = ''
      for (let i = 0; i < this.bookingDockets['items'].length; ++i) {
        for (let m = 0; m < this.wasteMaterials.length; ++ m) {
          if (this.wasteMaterials[m].id == this.bookingDockets['items'][i].material) {
            waste_description = waste_description + this.wasteMaterials[m].name + '; '
          }
        }
      }
      return waste_description
    },
    CalculateEstimatedWeight() {
      let estimated_weight = 0
      for (let i = 0; i < this.bookingDockets['items'].length; ++i) {
        for (let m = 0; m < this.wasteMaterials.length; ++ m) {
          if (this.wasteMaterials[m].id == this.bookingDockets['items'][i].material) {
            if (this.bookingDockets['items'][i].bin_type == '240') {
              estimated_weight += this.wasteMaterials[m].weight_per_bin_240 * this.bookingDockets['items'][i].bins
            }
            if (this.bookingDockets['items'][i].bin_type == '360') {
              estimated_weight += this.wasteMaterials[m].weight_per_bin_360 * this.bookingDockets['items'][i].bins
            }
            if (this.bookingDockets['items'][i].bin_type == '660') {
              estimated_weight += this.wasteMaterials[m].weight_per_bin_660 * this.bookingDockets['items'][i].bins
            }
          }
        }
      }
      return estimated_weight
    },     
  },
  data() {
    return {
      jsonData: [],
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    console.log('DriverProcessView')
    this.$store.dispatch('app/getDriverBooking', this.bookingID)
    return
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {
        bookingsMethods.saveWasteDocketStatus(this.currentBooking.id, 'completed')

        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedTime = `${hours % 12 || 12}:${minutes}${ampm}`; // Format as 12-hour clock

        generalMethods.messageBox(`Job completed at ${formattedTime}`, 'You can edit this job until midnight today.', 'success');
      }
      this.$router.push('/driverprojects')
    },    
    getPostCodeLink(postCode) {
      return `https://maps.google.com/maps?q=${postCode}`
    },
    getPhoneLink(phoneNo) {
      return `tel:${phoneNo}`
    },
    GoToBackProjectsListPage() {
      this.$router.go(-1)
    },
  },
}
</script>
